import { legacy_createStore } from 'redux';

function nowRoute(state, action) {
    switch (action.type) {
        case 'japan':
            return 'japan';
        case 'about':
            return 'about';
        case 'course':
            return 'course';
        case 'team':
            return 'team';
        case 'korean':
            return 'korean';
        case 'franch':
            return 'franch';
        default:
            return 'index';
    }
}

let store = legacy_createStore(nowRoute);

export default store;