import style from './BrandComponent.module.css';
import Title from 'component/title/TitleComponent';

import event1 from 'assets/home/7/event1.png'
import event2 from 'assets/home/7/event2.png'
import event3 from 'assets/home/7/event3.png'
import event4 from 'assets/home/7/event4.png'
import event5 from 'assets/home/7/event5.png'
import btn from 'assets/home/7/btn.png'

import cert1 from 'assets/home/8/cert1.png'
import cert2 from 'assets/home/8/cert2.png'

import { useState, useEffect } from 'react';

const Brand = (props) => {
    const { } = props;
    const width = window.innerWidth;
    const [x, setX] = useState(1);

    useEffect(() => {
        // 组件挂载完成时的逻辑
        console.log(`Component mounted with props:`);
        const intervalId = setInterval(() => {
            setX(x => x + 1);
        }, 5000);
        // 清理函数
        return () => {
            console.log(`Component will unmount`);
            clearInterval(intervalId)
        };
    }, []);
    return (
        <div className={style.box} >
            <Title
                className={style.title_box}
                mtX='0.71vw'
                title='ブランドの動向'
                subtitle='品牌动态'
                aline='center' />

            <div className={style.event_box}>
                <img src={event1} alt='' onClick={() => {
                    window.open('https://edu.cnr.cn/list/20221219/t20221219_526098739.shtml')
                }} />
                <img src={event2} alt='' onClick={() => {
                    window.open('https://page.om.qq.com/page/Or_b3dY4KEvt4153PpbcnE4w0')
                }} />
                <img src={event3} alt='' onClick={() => {
                    window.open('https://edu.cnr.cn/gc/20230726/t20230726_526346281.shtml')
                }} />
                <img src={event4} alt='' onClick={() => {
                    window.open('https://edu.cnr.cn/eduzt/2023jyzs/dhgk/dt/20231008/t20231008_526443843.shtml')
                }} />
                <img src={event5} alt='' onClick={() => {
                    window.open('https://edu.cnr.cn/hd/20231225/t20231225_526533279.shtml')
                }} />
                {/* <img src={btn} className={style.btn} alt='' onClick={() => {
                    console.log('查看更多')
                }} /> */}
            </div>

            {/* <Title
                className={style.title_box}
                mtX='0.71vw'
                title='栄誉と資格'
                subtitle='荣誉资质'
                aline='center' />

            <div className={style.cert} style={{
                backgroundImage: `url(${cert1})`,
                backgroundPositionX: `-${width > 820 ? x * 300 : x * 100}px`
            }}></div>
            <div className={style.cert} style={{
                backgroundImage: `url(${cert2})`,
                backgroundPositionX: `${width > 820 ? x * 300 : x * 100}px`
            }}></div> */}
        </div>
    );
};

export default Brand;