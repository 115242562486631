import './App.css';
import 'swiper/css';

import Header from './component/header/HeaderComponent';


import nowRoute from './redux/store'


import Japan from './page/Japan/JapanComponent';
import Course from './page/Course/CourseComponent';
import Team from './page/Team/TeamComponent';
import About from './page/About/AboutComponent';
import Korean from './page/Korean/KoreanComponent';
import Franch from './page/Franch/FranchComponent';

import modalPc from './assets/modalPc.png'
import modalMobile from './assets/modalMobile.png'
import moreBtn from './assets/index/more.png'
import leftBottomLogo from './assets/index/logo_left_bottom.png'
import japanLogo from './assets/index/japan_logo.png'
import koreanLogo from './assets/index/korean_logo.png'
import franchLogo from './assets/index/franch_logo.png'
import japanLogoM from './assets/index/japan_logo_mobile.png'
import koreanLogoM from './assets/index/korean_logo_mobile.png'
import franchLogoM from './assets/index/franch_logo_mobile.png'
import touchLogo from 'assets/index/logo_touch.png'

import japanModalPc from './assets/japanModalPc.png'
import japanModalMobile from './assets/japanModalMobile.png'
import koreanModalPc from './assets/koreanModalPc.png'
import koreanModalMobile from './assets/koreanModalMobile.png'

import auditionPc from './assets/auditionPc.png'
import auditionMobile from './assets/auditionMobile.png'

import closeBtn from './assets/close.png'


import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import title from './assets/index/title.png'
import titleM from './assets/index/titleM.png'

function App() {

    //测试弹窗，先取消 
    const [showMask, setShowMask] = useState(false);


    const [showConsult, setShowConsult] = useState(false);
    const [showKoreanConsult, setShowKoreanConsult] = useState(false);
    const [showAudition, setShowAudition] = useState(false);

    const [onJapan, setOnJapan] = useState(false);
    const [onKorean, setOnKorean] = useState(false);
    const [onFranch, setOnFranch] = useState(false);

    const width = window.innerWidth;

    const clickMenu = (str) => {
        if (str === 'app') {
            nowRoute.dispatch({ type: 'japan' });
            setTimeout(() => {
                console.log(document.getElementById('jpapp').offsetTop)

                window.scrollTo(0, document.getElementById('jpapp').offsetTop);
            })
            return;
        }
        if (str === 'KoreanApp') {
            nowRoute.dispatch({ type: 'korean' });
            setTimeout(() => {
                console.log(document.getElementById('krapp').offsetTop)

                window.scrollTo(0, document.getElementById('krapp').offsetTop);
            })
            return;
        }
        if (str === 'FranchApp') {
            nowRoute.dispatch({ type: 'franch' });
            setTimeout(() => {
                console.log(document.getElementById('frapp').offsetTop)

                window.scrollTo(0, document.getElementById('frapp').offsetTop);
            })
            return;
        }
        nowRoute.dispatch({ type: str })
        window.scrollTo(0, 0);
    }

    const showAuditionModal = () => {
        // window.open('https://work.weixin.qq.com/kfid/kfcaf8715a762dd294b', '_blank')
        setShowConsult(true);

        // setShowAudition(true);
    }

    const showConsultModal = () => {
        window.open('https://work.weixin.qq.com/kfid/kfcaf8715a762dd294b', '_blank')

    }

    const showJapanModal = () => {
        setShowConsult(true);
    }

    const showKoreanModal = () => {
        setShowKoreanConsult(true)
    }


    useEffect(() => {
        // 组件挂载完成时的逻辑
        console.log(`Component mounted with props:`);

        setTimeout(() => {
            setShowMask(false)
        }, 4000)

        // 清理函数
        return () => {
            console.log(`Component will unmount`);
        };
    }, []);

    return (
        <div className="App bgf6f7f9">
            <Header clickMenu={clickMenu}></Header>
            {
                nowRoute.getState() === 'index' &&
                <div className='bg'>
                    <div className='index-title'>
                        <img src={width > 820 ? title : titleM} alt='' />
                    </div>
                    <div className='guide-box'>
                        <div className='guide-logo' onMouseMove={() => {
                            setOnJapan(false);
                            setOnFranch(true);
                            setOnKorean(false);
                        }} onMouseLeave={() => {
                            setOnFranch(false)
                        }}>
                            <img src={width > 820 ? franchLogo : franchLogoM} alt='' onClick={() => {
                                nowRoute.dispatch({ type: 'franch' });
                            }} />
                            {width > 820 && onFranch && <img src={touchLogo} className='touch_logo' alt='' />}
                        </div>
                        <div className='guide-logo' onMouseMove={() => {
                            setOnJapan(true);
                            setOnFranch(false);
                            setOnKorean(false);
                        }} onMouseLeave={() => {
                            setOnJapan(false)
                        }} style={{
                            left: '24vw'
                        }}>
                            <img src={width > 820 ? japanLogo : japanLogoM} alt='' onClick={() => {
                                nowRoute.dispatch({ type: 'japan' });
                            }} />
                            {width > 820 && onJapan && <img src={touchLogo} className='touch_logo' alt='' />}
                        </div>
                        <div className='guide-logo' onMouseMove={() => {
                            setOnJapan(false);
                            setOnFranch(false);
                            setOnKorean(true);
                        }} onMouseLeave={() => {
                            setOnKorean(false)
                        }} style={{
                            // left: '24vw'
                            left: '48vw'
                        }}>
                            <img src={width > 820 ? koreanLogo : koreanLogoM} alt='' onClick={() => {
                                nowRoute.dispatch({ type: 'korean' });
                            }} />
                            {width > 820 && onKorean && <img src={touchLogo} className='touch_logo' alt='' />}
                        </div>

                    </div>
                    {!onJapan && !onFranch && !onKorean && <img src={leftBottomLogo} className='left-bottom-logo' alt='' />}
                    <img src={moreBtn} className='more-btn' alt='' onClick={() => {
                        nowRoute.dispatch({ type: 'about' });
                    }} />
                </div>
            }


            {
                showMask && <div className='mask'>
                    <img className='modal' src={width > 820 ? modalPc : modalMobile} alt='' />
                    <img className='modal_close' src={closeBtn} alt='' onClick={() => {
                        setShowMask(false);
                    }} />
                </div>}

            {
                showKoreanConsult && <div className='mask'>
                    <div className='modal bigModal' style={{
                        backgroundImage: `url(${width > 820 ? koreanModalPc : koreanModalMobile})`,
                        backgroundSize: '100% 100%'
                    }}>
                        <img className='modal_close' src={closeBtn} alt='' onClick={() => {
                            setShowKoreanConsult(false);
                        }} />
                    </div>
                </div>}

            {
                showConsult && <div className='mask'>
                    <div className='modal bigModal' style={{
                        backgroundImage: `url(${width > 820 ? japanModalPc : japanModalMobile})`,
                        backgroundSize: '100% 100%'
                    }}>
                        <img className='modal_close' src={closeBtn} alt='' onClick={() => {
                            setShowConsult(false);
                        }} />
                    </div>
                </div>}

            {
                showAudition && <div className='mask'>
                    <img className='modal bigModal' src={width > 820 ? auditionPc : auditionMobile} alt='' />
                    <img className='modal_close' src={closeBtn} alt='' onClick={() => {
                        setShowAudition(false);
                    }} />
                </div>}




            {
                nowRoute.getState() === 'japan' && <Japan clickMenu={clickMenu} showAuditionModal={showAuditionModal} showConsultModal={showJapanModal} />
            }

            {
                nowRoute.getState() === 'about' && <About clickMenu={clickMenu} showConsultModal={showConsultModal} />
            }

            {
                nowRoute.getState() === 'korean' && <Korean showAuditionModal={showAuditionModal} showConsultModal={showKoreanModal} />
            }

            {
                nowRoute.getState() === 'franch' && <Franch showAuditionModal={showAuditionModal} />
            }


        </div>
    );
}

export default App;
