import style from './ServiceComponent.module.css';
import Title from '../../../../component/title/TitleComponent';

import service1 from '../../../../assets/home/3/1.png'
import service2 from '../../../../assets/home/3/2.png'
import service3 from '../../../../assets/home/3/3.png'

import hover_service1 from '../../../../assets/home/3/hover_1.png'
import hover_service2 from '../../../../assets/home/3/hover_2.png'
import hover_service3 from '../../../../assets/home/3/hover_3.png'

import logo from '../../../../assets/home/3/logo.png'
import ImgBox from '../../../../component/imgBox/ImgBoxComponent';

const imgs = [service1, service2, service3]
const hoverImgs = [hover_service1, hover_service2, hover_service3]

const Service = (props) => {
    const { clickMenu } = props;
    return (
        <div className={style.row} >
            <Title
                className={style.title_box}
                mtX='1.67vw'
                title='温度のある教育、教師でもあり、友達でもある'
                subtitle='温度教学 亦师亦友'
                aline='left' />

            <div className={style.img_box}>
                {imgs.map((item, i) => {
                    return <ImgBox img={item} hoverImg={hoverImgs[i]} key={i} />
                })}
                <img className={style.logo} src={logo} alt='' />
            </div>
        </div>
    );
};

export default Service;