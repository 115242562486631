import React, { useState } from 'react';

import style from './TransferComponent.module.css';

import backgroundImage1 from '../../assets/transfer/background1.png';
import backgroundImage2 from '../../assets/transfer/background2.png';

import arrow1 from '../../assets/transfer/arrow1.png';
import arrow2 from '../../assets/transfer/arrow2.png';


const Transfer = (props) => {
    const backgroundImages = [backgroundImage1, backgroundImage2]

    const { text1, text2,showConsultModal } = props;

    const [backgroundImageIndex, setBackgroundImageIndex] = useState(0);

    const handleMouseEnter = () => {
        setBackgroundImageIndex(1);
    };

    const handleMouseLeave = () => {
        setBackgroundImageIndex(0);
    };

    return (
        <div>
            <div className={style.backgroundImage} style={{ backgroundImage: `url(${backgroundImages[backgroundImageIndex]})` }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={showConsultModal}>
                <img className={style.arrowImage} src={backgroundImageIndex === 0 ? arrow1 : arrow2} style={{
                    left: backgroundImageIndex === 0 ? 0 : 'calc(100% - 5.2vw)'
                }} alt='' />
                {
                    backgroundImageIndex === 0 ?
                        <div className={style.text} style={{
                            opacity: backgroundImageIndex === 0 ? 1 : 0,
                            left: '5.2vw'
                        }}>
                            {text1}
                        </div> : <div className={style.text} style={{
                            opacity: backgroundImageIndex === 0 ? 0 : 1,
                            color: '#FE6900',
                            right: '5.2vw'
                        }}>
                            {text2}
                        </div>
                }
            </div>
        </div>
    );
};

export default Transfer;