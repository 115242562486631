import style from './koeranAppComponent.module.css';
import Title from 'component/title/TitleComponent';

import KoreanAppImg from 'assets/home/5/KoreanApp.png'

const KoreanApp = (props) => {
    return (
        <div id='krapp'>

            <Title
                className={style.title_box}
                mtX='0.78vw'
                title='총이아 한국어 APP'
                subtitle='冲鸭韩语APP'
                aline='center' />

            <div className={style.img_box}>
                <img className={style.app} src={KoreanAppImg} alt='' />
            </div>

        </div>
    );
};

export default KoreanApp;