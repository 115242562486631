import style from './AdvantagesComponent.module.css';
import Title from '../../../../component/title/TitleComponent';

import advantages from '../../../../assets/home/advantages/advantages.png'


const Advantages = (props) => {
    const { } = props;
    return (
        <div>
            <Title
                className={style.title_box}
                mtX='0.78vw'
                title='日本語を学ぶメリット'
                subtitle='学日语的好处'
                aline='center' />

            <div className={style.img_box}>
                <img className={style.app} src={advantages} alt='' />
            </div>
        </div>
    );
};

export default Advantages;