import style from './courseHightlightComponent.module.css';
import Title from 'component/title/TitleComponent';


import logo from 'assets/korean/hightlight/logo.png';
import hightlight1 from 'assets/korean/hightlight/1.png';
import hightlight2 from 'assets/korean/hightlight/2.png';
import hightlight3 from 'assets/korean/hightlight/3.png';
import hightlight4 from 'assets/korean/hightlight/4.png';
import hightlight5 from 'assets/korean/hightlight/5.png';
import hightlight6 from 'assets/korean/hightlight/6.png';

import study from 'assets/korean/hightlight/study.png';

const CourseHightlight = (props) => {
    const { title, subtitle } = props;


    const width = window.innerWidth;

    return (
        <div >
            <div className={style.title_box}>
                <Title
                    className={style.title}
                    mtX='0.83vw'
                    title={title}
                    subtitle={subtitle}
                    aline='left' />
                <div className={style.logo}>
                    <img src={logo} alt='' />
                </div>
            </div>

            <div className={style.row}>
                <img className={style.img1} src={hightlight1} alt='' />
                <img className={style.img2} src={hightlight2} alt='' />
                <img className={style.img3} src={hightlight3} alt='' />
            </div>
            <div className={style.row}>
                <img className={style.img1} src={hightlight4} alt='' />
                <img className={style.img2} src={hightlight5} alt='' />
                <img className={style.img3} src={hightlight6} alt='' />
            </div>

            <Title
                className={style.title_box2}
                mtX='1.67vw'
                title='학습 효과'
                subtitle='学习辅导'
                aline='center' />
            <div className={style.img_box}>
                <img className={style.app} src={study} alt='' />
            </div>
        </div >
    );
};

export default CourseHightlight;