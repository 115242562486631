import style from './serviceComponent.module.css';
import Title from 'component/title/TitleComponent';

import service1 from 'assets/korean/service/1.png';
import { useState, useEffect } from 'react';


const Service = (props) => {
    const { } = props;
    const width = window.innerWidth;
    const [x, setX] = useState(1);

    useEffect(() => {
        // 组件挂载完成时的逻辑
        console.log(`Component mounted with props:`);
        const intervalId = setInterval(() => {
            setX(x => x + 1);
        }, 5000);
        // 清理函数
        return () => {
            console.log(`Component will unmount`);
            clearInterval(intervalId)
        };
    }, []);
    return (
        <div>

            <Title
                className={style.title_box}
                mtX='0.78vw'
                title='전속적인 서비스'
                subtitle='专属的服务'
                aline='center' />

            <div className={style.service} style={{
                backgroundImage: `url(${service1})`,
                backgroundPositionX: `-${width > 820 ? x * 300 : x * 100}px`
            }}></div>
        </div>
    );
};

export default Service;