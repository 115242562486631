import style from './JapaneseAppComponent.module.css';
import Title from 'component/title/TitleComponent';

import line from 'assets/home/5/line.png'
import appImg from 'assets/home/5/app.png'
import bookImg from 'assets/home/5/book.png'
import classImg from 'assets/home/5/class.png'


const JapaneseApp = (props) => {
    const { showConsultModal } = props;
    return (
        <div id='jpapp'>
            <Title
                className={style.title_box}
                mtX='0.78vw'
                title='日本語APP'
                subtitle='百万用户日语APP'
                aline='center' />

            <div className={style.img_box}>
                <img className={style.line} src={line} alt='' />
                <img className={style.app} src={appImg} alt='' />
            </div>

            

            <Title
                className={style.title_box}
                mtX='0.78vw'
                title='学習資料の周到な準備'
                subtitle='学习材料贴心整理'
                aline='center' />

            <div className={style.img_box}>
                <img className={style.app} style={{
                    cursor: 'pointer',
                    marginTop: '4vw'
                }} src={bookImg} alt='' onClick={showConsultModal} />
            </div>

            <Title
                className={style.title_box}
                mtX='0.78vw'
                title='デュアルカリキュラム'
                subtitle='双课程体系'
                aline='center' />

            <div className={style.img_box}>
                <img className={style.line} src={line} alt='' />
                <img className={style.app} style={{
                    cursor: 'pointer'
                }} src={classImg} alt='' onClick={showConsultModal} />
            </div>
        </div>
    );
};

export default JapaneseApp;