import React, { useState } from 'react';

import style from './AuditionComponent.module.css';
import Title from 'component/title/TitleComponent';

import img1 from 'assets/korean/audition/audioPic.png';


import btn from 'assets/home/audition/btn.png';
import btn_hover from 'assets/home/audition/btn_hover.png';

import video1 from 'assets/korean/audition/video.mp4'

const Audition = (props) => {
    const { showAuditionModal } = props;
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };
    return (
        <div className={style.row} >
            <Title
                className={style.title_box}
                mtX='0.83vw'
                title='전문 과정 미리듣기'
                subtitle='试听专业课'
                aline='center' />

            <div className={style.img_box}>
                <div>
                    <video className="video" poster={img1} src={video1} controls="controls"></video>
                </div>
            </div>

            <div className={style.img_box} style={{
                marginTop: '1.875vw'
            }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div onClick={showAuditionModal}><img src={isHover ? btn : btn_hover} alt='' /></div>
            </div>
        </div>
    );
};

export default Audition;