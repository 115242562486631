import style from './productComponent.module.css';
import Title from 'component/title/TitleComponent';

import product from 'assets/franch/product/product.png'

const Product = (props) => {
    return (
        <div id='frapp'>

            <Title
                className={style.title_box}
                mtX='0.78vw'
                title='FLes quatre grands produits de cours'
                subtitle='4大课程产品'
                aline='center' />

            <div className={style.img_box}>
                <img className={style.app} src={product} alt='' />
            </div>

        </div>
    );
};

export default Product;