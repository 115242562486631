import style from './TeachModeComponent.module.css';
import Title from '../../../../component/title/TitleComponent';
import { Swiper, SwiperSlide } from 'swiper/react';


import teachMode1 from '../../../../assets/home/4/1.png'
import teachMode2 from '../../../../assets/home/4/2.png'
import teachMode3 from '../../../../assets/home/4/3.png'
import teachMode4 from '../../../../assets/home/4/4.png'
import teachMode5 from '../../../../assets/home/4/5.png'
import teachMode6 from '../../../../assets/home/4/6.png'
import teachMode7 from '../../../../assets/home/4/7.png'
import teachMode8 from '../../../../assets/home/4/8.png'

import hover_teachMode1 from '../../../../assets/home/4/hover_1.png'
import hover_teachMode2 from '../../../../assets/home/4/hover_2.png'
import hover_teachMode3 from '../../../../assets/home/4/hover_3.png'
import hover_teachMode4 from '../../../../assets/home/4/hover_4.png'
import hover_teachMode5 from '../../../../assets/home/4/hover_5.png'
import hover_teachMode6 from '../../../../assets/home/4/hover_6.png'
import hover_teachMode7 from '../../../../assets/home/4/hover_7.png'
import hover_teachMode8 from '../../../../assets/home/4/hover_8.png'



import ImgBox from '../../../../component/imgBox/ImgBoxComponent';

const imgs = [teachMode1, teachMode2, teachMode3, teachMode4, teachMode5, teachMode6, teachMode7, teachMode8]
const hoverImgs = [hover_teachMode1, hover_teachMode2, hover_teachMode3, hover_teachMode4, hover_teachMode5, hover_teachMode6, hover_teachMode7, hover_teachMode8]

const TeachMode = (props) => {
    const { } = props;
    const width = window.innerWidth;
    return (
        <div >
            <Title
                className={style.title_box}
                mtX='1.56vw'
                title='新しいオンライン教育方法'
                subtitle='教研团队研究全新线上教学模式'
                aline='center' />

            <div className={style.img_box}>
                <Swiper
                    id='mySwiper'
                    spaceBetween={width > 820 ? 20 : 0}
                    slidesPerView={width > 820 ? 6.2 : 4.5}
                //loop={true}
                >
                    {imgs.map((item, i) => {
                        return <SwiperSlide key={i} >
                            <ImgBox img={item} hoverImg={hoverImgs[i]} />
                        </SwiperSlide>
                    })}
                </Swiper>
            </div>
        </div>
    );
};

export default TeachMode;