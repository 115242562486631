import style from './KoreanComponent.module.css';

import AboutChild from '../About/component/aboutChild/AboutChildComponent'
import Introduce from './component/introduce/IntroduceComponent';
import Audition from './component/audition/AuditionComponent';
import KoreanApp from './component/koeranApp/koeranAppComponent';

import Transfer from 'component/transfer/TransferComponent';

import trouble1 from 'assets/korean/troubles/1.png';
import trouble2 from 'assets/korean/troubles/2.png';
import trouble3 from 'assets/korean/troubles/3.png';
import trouble4 from 'assets/korean/troubles/4.png';
import qrcode from 'assets/korean/qrcode.png';
import banner from 'assets/banner/koreanbanner.png'
import line from 'assets/banner/line1.png'
import data1 from 'assets/korean/data/1.png';
import data2 from 'assets/korean/data/2.png';
import data3 from 'assets/korean/data/3.png';

import teacher4 from 'assets/team/teacher4.png'
import teacher5 from 'assets/team/teacher5.png'
import teacher6 from 'assets/team/teacher6.png'
import teacher7 from 'assets/team/teacher7.png'


import Title from 'component/title/TitleComponent';

import Footer from 'component/footer/FooterComponent';
import Service from './component/service/serviceComponent';
import DatasCom from './component/datas/datasComponent';
import CourseHightlight from './component/courseHightlight/courseHightlightComponent';
import Advantages from './component/advantages/AdvantagesComponent';

import CourseProduct from 'page/Japan/component/courseProduct2/CourseProductComponent';

const troubles = [trouble1, trouble2, trouble3, trouble4];
const datas = [data1, data2, data3]

const Korean = (props) => {
    const { clickMenu, showAuditionModal, showConsultModal } = props;
    // const showConsultModal = () => {
    //     //window.open('https://work.weixin.qq.com/kfid/kfc83ed383947cd59b9', '_blank')
    //     console.log('333');
    // }



    return (
        <div>
            <img className='qrcode' src={qrcode} alt='' />

            <div className='carousel_box'>
                <div className='carousel'>
                    <img src={banner} alt='' />
                </div>
                <img className='carousel_line' alt='' src={line}></img>
            </div>
            <AboutChild
                title='한국어 배울 때 이런 문제도 있다면'
                subtitle='如果你也遇到这些学韩语的困难'
                imgs={troubles}
                isHome='true'
            />
            <Transfer text1='找专业老师帮你解决' text2='点击免费咨询' showConsultModal={showConsultModal} />

            {/* <Introduce showConsultModal={showConsultModal} /> */}
            <CourseProduct type='korean' clickMenu={showConsultModal}  />
            <Transfer text1='如何选课？' text2='咨询专业老师' showConsultModal={showConsultModal} />

            <CourseHightlight title='과정의 브라이드 스폿'
                subtitle='课程亮点' />

            <Transfer text1='立即报名' text2='立即报名' showConsultModal={showConsultModal} />
            <Audition showAuditionModal={showConsultModal} />

            <Advantages />
            <Transfer text1='想提升更多？' text2=' 立即咨询专业老师' showConsultModal={showConsultModal} />
            <Title
                className={style.title_box}
                mtX='0.83vw'
                title='지도교수 팀'
                subtitle='老师简介'
                aline='center' />

            <div className={style.row} style={{
                marginTop: '6.25vw',
                paddingLeft: '4.64vw'
            }}>
                <div className={style.img_box}>
                    <img src={teacher4} alt='' />
                </div>
                <div className={style.content_box} style={{
                    paddingLeft: '2vw'
                }}>
                    <div className={style.title}>
                        庆熙大学硕士
                    </div>
                    <div className={style.content}>
                        四年在韩留学经验
                        <br />
                        八年线上线下韩语教学经验
                        <br />
                        韩语TOPIK6级 257分
                        <br />
                        持有商务韩语教师资格证
                        <br /><br />
                        韩式料理大师级水平
                        <br />
                        上课严谨又不失幽默
                    </div>
                </div>
            </div>
            <div className={style.row} style={{
                paddingLeft: '12vw',
                paddingRight: '4.64vw'
            }}>
                <div className={style.content_box} style={{
                    paddingRight: '2vw'
                }}>
                    <div className={style.title}>
                        淑明女子大学博士
                    </div>
                    <div className={style.content}>
                        12年在韩留学和工作经历
                        <br />
                        6年韩国语教学经验
                        <br />
                        持有韩语教师资格证二级证
                        <br /><br />
                        曾在淑明女子大学、三育大学、
                        <br />
                        信韩大学等任职韩国语讲师
                    </div>
                </div>
                <div className={style.img_box}>
                    <img src={teacher5} alt='' />
                </div>
            </div>
            <div className={style.row} style={{
                paddingLeft: '4.64vw'
            }}>
                <div className={style.img_box}>
                    <img src={teacher6} alt='' />
                </div>
                <div className={style.content_box} style={{
                    paddingLeft: '2vw'
                }}>
                    <div className={style.title}>
                        湖南师范大学硕士
                    </div>
                    <div className={style.content}>
                        韩国圆光大学本科
                        <br />
                        中文能力考试HSK5级
                        <br />
                        5年教学经验
                        <br /><br />
                        地道首尔发音
                        <br />
                        口语教学经验丰富
                    </div>
                </div>
            </div>
            <div className={style.row} style={{
                paddingLeft: '12vw',
                paddingRight: '4.64vw'
            }}>
                <div className={style.content_box} style={{
                    paddingRight: '2vw'
                }}>
                    <div className={style.title}>
                        高分通过TOPIK6级
                    </div>
                    <div className={style.content}>
                        韩语专业科班出身
                        <br />
                        5年韩语教学经验
                        <br /><br />
                        声线甜美
                        <br />
                        教学风格活泼
                        <br />
                        多维全能型韩语讲师
                    </div>
                </div>
                <div className={style.img_box}>
                    <img src={teacher7} alt='' />
                </div>
            </div>

            <KoreanApp />
            <Service />


            <DatasCom
                title='풍요한 학습 자료 무료 취득'
                subtitle='免费获取丰富学习资料'
                imgs={datas}
                isHome='true'
                showConsultModal={showConsultModal}
            />
            <Transfer text1='立即免费下载' text2='立即免费下载' showConsultModal={showConsultModal} />
            <Footer type='Korean' />
        </div>
    );
};

export default Korean;