import style from './serviceComponent.module.css';
import Title from 'component/title/TitleComponent';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import tower from 'assets/franch/services/tower.png';

import service1 from 'assets/franch/services/1.png'
import service2 from 'assets/franch/services/2.png'
import service3 from 'assets/franch/services/3.png'
import service4 from 'assets/franch/services/4.png'
import service5 from 'assets/franch/services/5.png'

import left_btn from 'assets/franch/services/left_btn.png'
import right_btn from 'assets/franch/services/right_btn.png'

import { createRef } from 'react';

const Services = (props) => {
    const imgs = [service1, service2, service3, service4, service5]
    const swiper = createRef()

    return (
        <div className={style.container}>

            <Title
                className={style.title_box}
                mtX='0.78vw'
                title='Services exclusifs'
                subtitle='专属服务'
                aline='left' />

            <div className={style.img_box}>
                <div className={style.left_box}>
                    <img className={style.left_btn} src={left_btn} alt='' onClick={() => swiper.current.swiper.slidePrev()} />
                    <img className={style.right_btn} src={right_btn} alt='' onClick={() => swiper.current.swiper.slideNext()} />
                    <Swiper
                        ref={swiper}
                        spaceBetween={20}
                        slidesPerView={2}
                    >
                        {imgs.map((item, i) => {
                            return <SwiperSlide key={i} >
                                <img src={item} alt='' />
                            </SwiperSlide>
                        })}
                    </Swiper>
                </div>
                <div className={style.right_box}>
                    <img src={tower} alt='' />
                </div>
            </div>

        </div >
    );
};

export default Services;