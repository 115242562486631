import style from './IntroduceComponent.module.css';
import Title from '../../../../component/title/TitleComponent';
import { useState } from 'react'

import course1 from '../../../../assets/course/1/1.png'
import course2 from '../../../../assets/course/1/2.png'
import course3 from '../../../../assets/course/1/3.png'
import course4 from '../../../../assets/course/1/4.png'
import TitleContent from '../titleContent/titleContentComponent';

const Introduce = (props) => {
    const { showConsultModal } = props;

    const [courseImg, setCourseImg] = useState(course1);

    const hoverCourse = (index) => {
        const courseArr = [course1, course2, course3, course4]
        setCourseImg(courseArr[index - 1]);
    };


    return (
        <div className={style.box} id='courseIntroduce'>
            <Title
                className={style.title_box}
                mtX='0.83vw'
                title='コースの紹介'
                subtitle='课程产品介绍'
                aline='center' />

            <div className={style.courses}>
                <div className={style.left_box}>
                    <TitleContent hover={hoverCourse} img={course1} title='総合能力クラス' subtitle='能力综合班' />
                    <TitleContent hover={hoverCourse} img={course2} title='大学受験/大学院受験コース' subtitle='高考/专研专项班' />
                    <TitleContent hover={hoverCourse} img={course3} title='VIPハイスコアクラス' subtitle='考研VIP高分班' />
                    <TitleContent hover={hoverCourse} img={course4} title='1対1のプライベートレッスン' subtitle='1v1私教课' />
                </div>
                <div className={style.right_box}>
                    <img src={courseImg} alt='' style={{
                        cursor: 'pointer'
                    }} onClick={showConsultModal} />
                </div>
            </div>

        </div >
    );
};

export default Introduce;