import style from './datasComponent.module.css';
import Title from 'component/title/TitleComponent';
import ImgBox from 'component/imgBox/ImgBoxComponent';
import btn from 'assets/korean/data/btn.png';


const DatasCom = (props) => {
    const { title, subtitle, imgs, showConsultModal } = props;


    const width = window.innerWidth;

    return (
        <div >
            <Title
                className={style.title_box}
                mtX='0.83vw'
                title={title}
                subtitle={subtitle}
                aline='center' />

            <div className={style.img_box}>
                {imgs.map((item, i) => {
                    return <div className={style.single_img} key={i} onClick={() => {
                    }} style={{
                        width: '26vw',
                        flex: '1'
                    }}>
                        <img src={item} alt='' />
                        <img src={btn} className={style.btn} alt='' onClick={showConsultModal} />
                    </div>

                })}
            </div>
        </div >
    );
};

export default DatasCom;