import style from './EducationComponent.module.css';
import Title from '../../../../component/title/TitleComponent';

import leftPic from '../../../../assets/about/1/1.png';
import rightPic from '../../../../assets/about/1/2.png';

const Education = (props) => {
    const { } = props;

    return (
        <div className={style.box} >

            {/* <div className={style.container}>
                <div className={style.img_box}>
                    <img src={leftPic} alt='' />
                </div>
                <div className={style.content_box}>
                    <Title
                        className={style.title_box}
                        mtX='0.42vw'
                        title='チョンダック教育'
                        subtitle='冲鸭教育'
                        aline='left' />
                    <div className={style.content}>
                        <br />
                        冲鸭教育隶属于互联网教育领导者，湖南冲鸭教育科技有限公司是一家以自有研发小语种课程内容体系和强监督服务模式为特色的“互联网+教育”创新科技企业。
                        <br />
                        <br />
                        始于2018年一群中国留学生于日本创立的中文学堂，历经五年快速发展，已帮助5万+学员进行深度语言学习。
                        <br />
                        <br />
                        教师团队由名校留学生组建，专业过硬，多年教育实战经验给予了学员高质量、强体验的教学保证。
                    </div>
                </div>
            </div> */}

            <div className={style.container}>
                <div className={style.content_box} style={{
                    padding: '0 2vw',
                }}>
                    <Title
                        className={style.title_box}
                        mtX='0.42vw'
                        title='教育の理念'
                        subtitle='教育理念'
                        aline='left' />
                    <div className={style.content}>
                        <br />
                        专注于解决日韩法语等小语种爱好者、英语薄弱高考生、考研学生的小语种学习问题，旨在培养听说读写全面发展的日韩法语爱好者，培养各行业小语种实战应用型人才，打造高质量新式小语种语学习课堂。
                        <br />
                        <br />
                        致力于打造专业、好玩、实用、负责的线上小语种教育课堂。提倡“严谨而不严肃”的教学风格，以严谨态度把关教学质量，以轻松愉悦的学习氛围，用心的服务态度，让每位学员实现“寓教于乐”的教学体验。
                        <br />
                        <br />
                        在线视频课程精讲+直播难点回顾+学习任务墙实训的特色学习模式，进行思维及创新  教学探究。
                    </div>
                </div>
                <div className={style.img_box}>
                    <img src={rightPic} alt='' />
                </div>

            </div>

        </div>
    );
};

export default Education;