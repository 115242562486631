import style from './AssuranceComponent.module.css';
import Title from '../../../../component/title/TitleComponent';
import { useState } from 'react'

import assurance from '../../../../assets/course/3/content.png'
const Assurance = (props) => {
    const { } = props;




    return (
        <div className={style.box}>
            <Title
                className={style.title_box}
                mtX='0.78vw'
                title='コースサービス保証'
                subtitle='课程服务保障'
                aline='center' />

            <div className={style.img_box}>
                <img src={assurance} alt='' />
            </div>

        </div >
    );
};

export default Assurance;