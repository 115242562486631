import style from './FooterComponent.module.css';
import JapanFoot from 'assets/foot/foot.png'
import KoreanFoot from 'assets/foot/KoreanFoot.png'
import FranchFoot from 'assets/foot/FranchFoot.png'
import AboutFoot from 'assets/foot/AboutFoot.png';

import beian from 'assets/foot/beian.png';

const Footer = (props) => {
    const { type } = props;
    return (
        <div className={style.App_footer}>
            <img className={style.beian} src={beian} alt='' onClick={() => {
                window.open('https://beian.miit.gov.cn/')
            }} />
            <img className={style.foot_bg} src={type === 'Japan' ? JapanFoot : type === 'Korean' ? KoreanFoot : type === 'about' ? AboutFoot : FranchFoot} alt='' />
        </div>
    );
};

export default Footer;