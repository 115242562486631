import style from './titleContentComponent.module.css';
import Title from '../../../../component/title/TitleComponent';


import { useState, useEffect } from 'react';

const TitleContent = (props) => {
    const { title, subtitle, img, hover } = props;
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = (e) => {
        console.log(e);
        const index = e.split('.')[1].substr(-1, 1)
        hover(index);
        setIsHovered(true);
    };

    const handleMouseLeave = (e) => {
        setIsHovered(false);
    };
    return (
        <div className={style.content}
            onMouseEnter={() => { handleMouseEnter(img) }}
            onMouseLeave={handleMouseLeave}
            style={{
                background: isHovered ? '#FE6900' : 'none',
                boxShadow: isHovered ? '0px 2px 56px 15px rgba(198, 198, 198, 0.09)' : 'none'
            }}>
            <div className={style.title}
                style={{
                    color: isHovered ? '#FFFFFF' : '#140E0A'
                }}>{title}</div>
            <div className={style.subtitle}
                style={{
                    color: isHovered ? 'rgba(255, 255, 255, 0.8)' : 'rgba(20, 14, 10, 0.51)'
                }}>{subtitle}</div>
        </div >
    );
};

export default TitleContent;