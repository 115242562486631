import style from './TitleComponent.module.css';


const Title = (props) => {
    const { title, title2, subtitle, aline, className, mtX } = props;
    return (
        <div style={{
            textAlign: aline
        }} className={className}
            id={subtitle === '冲鸭韩语APP' ? 'krapp' : ''}>
            <div className={style.title}>
                {title}
            </div>
            {
                title2 && <div className={style.title}>
                    {title2}
                </div>
            }
            <div className={style.subtitle} style={{
                marginTop: mtX
            }}>
                {subtitle}
            </div>
        </div>
    );
};

export default Title;