import style from './CourseHightlightComponent.module.css';
import Title from '../../../../component/title/TitleComponent';
import ImgBox from '../../../../component/imgBox/ImgBoxComponent';

import hightlight1 from '../../../../assets/home/2/1.png'
import hightlight2 from '../../../../assets/home/2/2.png'
import hightlight3 from '../../../../assets/home/2/3.png'
import hightlight4 from '../../../../assets/home/2/4.png'

import hightlight_hover_1 from '../../../../assets/home/2/hover_1.png'
import hightlight_hover_2 from '../../../../assets/home/2/hover_2.png'
import hightlight_hover_3 from '../../../../assets/home/2/hover_3.png'
import hightlight_hover_4 from '../../../../assets/home/2/hover_4.png'

const imgs = [hightlight1, hightlight2, hightlight3, hightlight4]
const hoverImgs = [hightlight_hover_1, hightlight_hover_2, hightlight_hover_3, hightlight_hover_4]

const CourseHightlight = (props) => {
    const { } = props;
    return (
        <div className={style.row} >
            <Title
                className={style.title_box}
                mtX='2.08vw'
                title='４つのコースのハイライト'
                subtitle='4大课程亮点'
                aline='left' />

            <div className={style.img_box}>
                {imgs.map((item, i) => {
                    return <ImgBox img={item} hoverImg={hoverImgs[i]} key={i} />
                })}
            </div>
        </div>
    );
};

export default CourseHightlight;