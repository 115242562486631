import React, { useState } from 'react';

import style from './AuditionComponent.module.css';
import Title from '../../../../component/title/TitleComponent';

import img1 from '../../../../assets/home/audition/1.png';
import img2 from '../../../../assets/home/audition/2.png';

import btn from '../../../../assets/home/audition/btn.png';
import btn_hover from '../../../../assets/home/audition/btn_hover.png';

import video1 from '../../../../assets/video/duotang.mp4'
import video2 from '../../../../assets/video/momo.mp4'

const Audition = (props) => {
    const { showAuditionModal } = props;
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };
    return (
        <div className={style.row} >
            <Title
                className={style.title_box}
                mtX='0.83vw'
                title='無料オンライン体験集中講義'
                subtitle='免费在线试听精讲课'
                aline='center' />

            <div className={style.img_box}>
                {/* <div>
                    <video className="video" poster={img1} src={video1} controls="controls"></video>
                </div> */}
                <div>
                    <video className="video" poster={img2} src={video2} controls="controls"></video>
                </div>
            </div>

            <div className={style.img_box} style={{
                marginTop: '1.875vw'
            }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div onClick={showAuditionModal}><img src={isHover ? btn : btn_hover} alt='' /></div>
            </div>
        </div>
    );
};

export default Audition;