import style from './JapanComponent.module.css';


import AboutChild from '../About/component/aboutChild/AboutChildComponent'

import trouble1 from 'assets/home/troubles/1.png';
import trouble2 from 'assets/home/troubles/2.png';
import trouble3 from 'assets/home/troubles/3.png';
import trouble4 from 'assets/home/troubles/4.png';
import Transfer from 'component/transfer/TransferComponent';
import workers from 'assets/worker/workers.png'

import banner from 'assets/banner/banner2.png'
import line from 'assets/banner/line1.png'
import qrcode from 'assets/qrcode.png'

import teacher1 from 'assets/team/teacher1.png'
import teacher2 from 'assets/team/teacher2.png'
import teacher3 from 'assets/team/teacher3.png'

import img1 from 'assets/team/1.png'
import img2 from 'assets/team/2.png'
import img4 from 'assets/team/4.png'
import img5 from 'assets/team/5.png'
import img6 from 'assets/team/6.png'
import img7 from 'assets/team/7.png'
import img8 from 'assets/team/8.png'
import img9 from 'assets/team/9.png'
import img10 from 'assets/team/10.png'
import img11 from 'assets/team/11.png'
import img12 from 'assets/team/12.png'
import img13 from 'assets/team/13.png'
import img14 from 'assets/team/14.png'

import achievements1 from 'assets/team/achievements1.png'
import achievements2 from 'assets/team/achievements2.png'

import Title from 'component/title/TitleComponent';
import Footer from 'component/footer/FooterComponent';


import Advantages from './component/advantages/AdvantagesComponent';
import CourseProduct from './component/courseProduct/CourseProductComponent';
import CourseProduct2 from './component/courseProduct2/CourseProductComponent';

import Audition from './component/audition/AuditionComponent';
import CourseHightlight from './component/courseHightlight/CourseHightlightComponent';
import Service from './component/service/ServiceComponent';
import TeachMode from './component/teachMode/TeachModeComponent';
import JapaneseApp from './component/japaneseApp/JapaneseAppComponent';

import Introduce from './component/introduce/IntroduceComponent';
import Assurance from './component/assurance/AssuranceComponent';

import { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';



const troubles = [trouble1, trouble2, trouble3, trouble4];
const imgs = [img1, img2, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14]

const Japan = (props) => {
    const [x, setX] = useState(1);

    const width = window.innerWidth;
    const { clickMenu, showAuditionModal, showConsultModal } = props;

    useEffect(() => {
        // 组件挂载完成时的逻辑
        console.log(`Component mounted with props:`);
        const intervalId = setInterval(() => {
            setX(x => x + 1);
        }, 3000);

        // 清理函数
        return () => {
            console.log(`Component will unmount`);
            clearInterval(intervalId)
        };
    }, []);
    return (
        <div>
            <img className='qrcode' src={qrcode} alt='' />

            <div className='carousel_box'>
                <div className='carousel'>
                    <img src={banner} alt='' />
                </div>
                <img className='carousel_line' alt='' src={line}></img>
            </div>
            <AboutChild
                title='日本語学習の難しさ'
                subtitle='如果你也遇到这些学日语的困难'
                imgs={troubles}
                isHome='true'
            />
            <Transfer text1='找专业老师帮你解决' text2='点击免费咨询' showConsultModal={showConsultModal} />
            {/* <CourseProduct clickMenu={clickMenu} /> */}
            <CourseProduct2 type='japan' clickMenu={showConsultModal} />
            <Transfer text1='如何选课？' text2='咨询专业老师' showConsultModal={showConsultModal} />
            <Audition showAuditionModal={showAuditionModal} />
            <CourseHightlight />
            
            <Service clickMenu={clickMenu} />
            <Advantages />
            <Transfer text1='想提升更多？' text2=' 立即咨询专业老师' showConsultModal={showConsultModal} />
            <TeachMode />


            <Introduce showConsultModal={showConsultModal} />
            <Assurance />

            <Title
                className={style.title_box}
                mtX='0.83vw'
                title='講師プロフィール'
                subtitle='老师简介'
                aline='center' />

            {/* <div className={style.row} style={{
                marginTop: '6.25vw',
                paddingLeft: '4.64vw'
            }}>
                <div className={style.img_box}>
                    <img src={teacher1} alt='' />
                </div>
                <div className={style.content_box} style={{
                    paddingLeft: '2vw'
                }}>
                    <div className={style.title}>
                        名古屋大学学士
                    </div>
                    <div className={style.content}>
                        6年大型机构线上线下日语
                        <br />
                        教学经验
                        <br /><br />
                        大学裸考高分N1
                        <br />
                        学生眼里的宝藏老师
                        <br />
                        带出百位满分JLPT考级学
                        <br />
                        员教学风趣幽默又一针见血
                        <br />
                        精准应对不同学员的日语学习需求
                    </div>
                </div>
            </div> */}
            <div className={style.row} style={{
                paddingLeft: '12vw',
                paddingRight: '4.64vw'
            }}>
                <div className={style.content_box} style={{
                    paddingRight: '2vw'
                }}>
                    <div className={style.title}>
                        九州大学硕士
                    </div>
                    <div className={style.content}>
                        在日留学工作多年
                        <br />
                        日语教学经验五年多
                        <br /><br />
                        授课风格幽默风趣
                        <br />
                        教学方式深入浅出
                        <br />
                        深受学生好评
                    </div>
                </div>
                <div className={style.img_box}>
                    <img src={teacher2} alt='' />
                </div>
            </div>

            <Transfer text1='冲鸭专业教研团队' text2='选择你喜欢的' showConsultModal={showConsultModal} />

            <div className={style.teacher_box}>
                <Swiper
                    id='mySwiper'
                    spaceBetween={20}
                    slidesPerView={7.5}
                >
                    {imgs.map((item, i) => {
                        return <SwiperSlide key={i} >
                            <img src={item} alt='' />
                        </SwiperSlide>
                    })}
                </Swiper>
            </div>


            <Title
                className={style.title_box}
                mtX='0.83vw'
                title='教育成果'
                subtitle='教研成果'
                aline='center' />
            <div className={style.img_box2}>
                <img src={achievements1} alt='' />
            </div>
            <div className={style.img_box2}>
                <img src={achievements2} alt='' />
            </div>
            <JapaneseApp showConsultModal={showConsultModal} />
            
            <div>
                {/* <Title className='worker_title' title='関連報道/パートナーシップ' subtitle='媒体报道/合作伙伴' mtX='13px' aline='center' />

                <div className='workers' style={{
                    backgroundImage: `url(${workers})`,
                    backgroundPositionX: `-${width > 820 ? x * 300 : x * 80}px`
                }}></div> */}

                <Footer type='Japan' />
            </div>
        </div>
    );
};

export default Japan;