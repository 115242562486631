import style from './HistorycardComponent.module.css';


const Historycard = (props) => {
    const { time, month, content,img } = props;

    return (
        <div className={style.box} >
            <div className={style.imgbox}>
                <img src={img} alt="" />
            </div>
            <div className={style.contentbox}>
                <div className={style.timeBox}>
                    <span className={style.time}>{time}</span>
                    {month && <span className={style.month}>{month}</span>}
                </div>
                <div className={style.content} style={{ whiteSpace: "pre-line" }}>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default Historycard;